import { FC } from 'react';
import { Step, Stepper } from 'react-form-stepper';

const clearStepStyle = {
  activeBgColor: 'transparent',
  activeTextColor: 'transparent',
  completedBgColor: 'transparent',
  completedTextColor: 'transparent',
  inactiveBgColor: 'transparent',
  inactiveTextColor: 'transparent',
  size: '2em',
  circleFontSize: '1rem',
  labelFontSize: '0.875rem',
  borderRadius: '50%',
  fontWeight: 500,
};

export const CurrentSteps: FC = () => (
  <div className="py-5">
    <h3 className="py-5 text-2xl font-bold text-center">Launch Timeline</h3>
    <Stepper activeStep={1}>
      <Step label="Stage 1: Platform Development" styleConfig={clearStepStyle}>
        <img src={process.env.PUBLIC_URL + 'marker_orange.png'} />
      </Step>
      <Step label="Stage 2: User Enrollment" styleConfig={clearStepStyle}>
        <img src={process.env.PUBLIC_URL + 'marker_gray.png'} />
      </Step>
      <Step label="Stage 3: Equity Connections" styleConfig={clearStepStyle}>
        <img src={process.env.PUBLIC_URL + 'marker_gray.png'} />
      </Step>
    </Stepper>
  </div>
);
