import { FC } from 'react';

export const DesktopNav: FC<{
  scrollToSolutions: () => void;
  scrollToLeadership: () => void;
  scrollToContact: () => void;
}> = ({ scrollToSolutions, scrollToLeadership, scrollToContact }) => {
  return (
    <div className="bg-black shadow-sm flex justify-between px-10">
      <img src={process.env.PUBLIC_URL + 'BEPLogo.png'} alt="logo" className="h-16" />
      <nav className="flex items-center text-white">
        <p className="px-3 cursor-pointer" onClick={scrollToSolutions}>
          Our Solutions
        </p>
        <p className="px-3 cursor-pointer" onClick={scrollToLeadership}>
          Our Team
        </p>
        <p className="px-3 cursor-pointer" onClick={scrollToContact}>
          Contact Us
        </p>
      </nav>
    </div>
  );
};
