import { useState, useEffect } from 'react';

import { mediumBreakpoint, mobileBreakpoint } from '../utils/globalHardCodes';

// hook from https://stackoverflow.com/questions/66588340/custom-hook-for-window-resize
const getScreenSize = () =>
  window.innerWidth <= mobileBreakpoint ? 'sm' : window.innerWidth <= mediumBreakpoint ? 'md' : 'lg';

export default function useScreenSize() {
  const [screenSize, setScreenSize] = useState<'sm' | 'md' | 'lg'>(getScreenSize());

  useEffect(() => {
    const onResize = () => {
      setScreenSize(getScreenSize());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return screenSize;
}
