import { FC } from 'react';

export const Hamburger: FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <div onClick={() => (onClick ? onClick() : null)} className="space-y-2 cursor-pointer">
      <span className="block w-8 h-1 bg-gray-300"></span>
      <span className="block w-8 h-1 bg-gray-300"></span>
      <span className="block w-8 h-1 bg-gray-300"></span>
    </div>
  );
};
