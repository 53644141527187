import { FC, useState } from 'react';

import { Hamburger } from '../icons/Hamburger';

export const MobileNav: FC<{
  scrollToSolutions: () => void;
  scrollToLeadership: () => void;
  scrollToContact: () => void;
}> = ({ scrollToSolutions, scrollToLeadership, scrollToContact }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="bg-black shadow-sm flex justify-between px-5">
        <img src={process.env.PUBLIC_URL + 'BEPLogo.png'} alt="logo" className="h-16" />
        <div className="flex items-center text-white">
          {/* Trace todo - add hamburger menu that can be clicked */}
          <Hamburger onClick={() => setIsOpen(!isOpen)} />
        </div>
      </div>
      {isOpen ? (
        <div>
          <p
            className="px-3 py-1 text-lg cursor-pointer"
            onClick={() => {
              setIsOpen(false);
              scrollToSolutions();
            }}
          >
            Our Solutions
          </p>
          <p
            className="px-3 py-1 text-lg cursor-pointer"
            onClick={() => {
              setIsOpen(false);
              scrollToLeadership();
            }}
          >
            Our Team
          </p>
          <p
            className="px-3 cursor-pointer"
            onClick={() => {
              setIsOpen(false);
              scrollToContact();
            }}
          >
            Contact Us
          </p>
        </div>
      ) : null}
    </div>
  );
};
