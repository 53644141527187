import { FC } from 'react';

import { manLookingAtPhoneMaxWidths } from '../../utils/componentSpecificHardCodes';

export const QuestionsAndAnswers: FC = () => (
  <div className="bg-gray-300 px-[25%] sm:px-7">
    <div className="py-10 max-w-[1000px] m-auto">
      <div
        className={`sm:float-none float-left px-5 mr-2 -mx-2 py-5 max-w-[${manLookingAtPhoneMaxWidths.lg}] md:max-w-[${manLookingAtPhoneMaxWidths.md}}]`}
      >
        <img src={process.env.PUBLIC_URL + 'pexels-liliana-drew-8554855.jpg'} alt="man looking at phone" />
      </div>
      <h2 className="mt-3 py-2 text-2xl font-bold text-primary">Game-Changing Access to Financing</h2>
      <p className="text-lg">
        Today's financing options limit the ability of young Americans to access financing and saddle them with debt.
        Our platform will provide easy access to flexible financing that helps individuals invest in their future and
        achieve their long-term goals earlier.
      </p>
      <h2 className="mt-3 py-2 text-2xl font-bold">How does BEP work? </h2>
      <p className="text-lg">
        We’ve developed a new financial asset class in which investors provide upfront cash to individuals with earning
        potential in exchange for an equity stake in a portion of their future earnings. Our financial engagement
        platform allows the asset class connections to be made and paid.
      </p>
      <h2 className="mt-3 py-2 text-2xl font-bold">How is this different from a traditional loan? </h2>
      <p className="text-lg">
        Equity financing differs greatly from debt financing -- individuals sell a portion of their future earnings
        instead of borrowing money to pay back at a fixed rate. If the individual's earnings don't live up to
        expectations, they aren't left with crippling debt.
      </p>
      <h2 className="mt-3 py-2 text-2xl font-bold">Why do we do it?</h2>
      <p className="text-lg">
        Our founders are on a mission to solve a real problem in income inequality and social mobility by providing
        people with the resources and tools to maximize their earning potential, regardless of their socioeconomic
        backgrounds. We believe that every individual should have equal opportunity to invest in their future now.
      </p>
    </div>
  </div>
);
