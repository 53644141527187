import React, { FC, RefObject, useState } from 'react';

import { CallToActionHeader } from './CallToActionHeader';

export const CallToAction: FC<{ divRef: RefObject<HTMLDivElement> }> = ({ divRef }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState<'Individual' | 'Investor'>('Individual');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const response = await fetch('/.netlify/functions/handle-contact', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        name: name,
        email: email,
        role: role,
        phone: phone,
        message: message,
      },
      // body: JSON.stringify({
      //   name,
      //   email,
      //   role,
      //   phone,
      //   message,
      // }),
    });
    // const data = await response.json();
    const responseCode = response.status;
    if (responseCode === 200) {
      alert('Thank you for your message! We will get back to you shortly.');
      setName('');
      setEmail('');
      setRole('Individual');
      setPhone('');
      setMessage('');
    } else {
      alert('Something went wrong submitting your message to our team. Please try again later.');
    }
  };

  return (
    <React.Fragment>
      <CallToActionHeader divRef={divRef} />
      <form
        className="mt-10 max-w-[1000px] xs:min-w-[275px] m-auto xs:mx-1my-10 p-10 xs:p-3 rounded-md shadow-lg pb-20 mb-10"
        onSubmit={submitForm}
      >
        <p className="text-xl text-center font-bold py-10">Contact Us</p>
        <div className="grid grid-cols-2 sm:flex sm:flex-col">
          <div className="grid grid-cols-2 sm:grid-cols-1 items-center align-center">
            <label htmlFor="name" className="justify-self-end sm:justify-self-start sm:pt-5 pr-3 sm:pr-2">
              Name:
            </label>
            <input
              className="border-solid border-2 rounded-md p-1"
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="name"
              placeholder="Your name"
            />
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-1 items-center align-center">
            <label htmlFor="email" className="justify-self-end sm:justify-self-start sm:pt-5 pr-3 sm:pr-2">
              Email:
            </label>
            <input
              className="border-solid border-2 rounded-md p-1"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              placeholder="Your email"
            />
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-1 items-center align-center">
            <label className="justify-self-end sm:justify-self-start sm:pt-5 pr-3 sm:pr-2" htmlFor="role">
              Role
            </label>
            <select
              className="border-solid border-2 rounded-md p-1"
              id="role"
              value={role as 'Individual' | 'Investor'}
              onChange={(e) => setRole(e.target.value as 'Individual' | 'Investor')}
              name="role"
            >
              <option value="Individual">Individual</option>
              <option value="Investor">Investor</option>
            </select>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-1 items-center align-center">
            <label htmlFor="phone" className="justify-self-end sm:justify-self-start sm:pt-5 pr-3 sm:pr-2">
              Mobile:
            </label>
            <input
              className="border-solid border-2 rounded-md p-1"
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Your phone number"
            />
          </div>
          <div className="col-span-2 pl-20 sm:pl-1 py-5">
            <label className="justify-self-end sm:justify-self-start sm:pt-5" htmlFor="message">
              Message
            </label>
            <textarea
              className="border-solid border-2 rounded-md p-1 w-full"
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Your message"
            />
          </div>
        </div>
        <button type="submit" className="bg-primary text-white px-3 py-2 rounded-sm float-right">
          Learn More
        </button>
      </form>
    </React.Fragment>
  );
};
