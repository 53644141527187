import { FC } from 'react';
import styled from 'styled-components';

const BlockQuote = styled.div`
  display: flex;
  margin: 0.25em 0;
  padding: 0.35em 4em;
  line-height: 1.45;
  position: relative;

  @media only screen and (min-width: 768px) {
    &::before {
      display: block;
      padding-left: 10px;
      content: url('/leftquote.png');
      font-size: 80px;
      position: absolute;
      left: -20px;
      top: -20px;
    }

    &::after {
      align-self: end;
      justify-self: center;
      content: url('/rightquote.png');
    }
  }
`;
export const QuoteFromCEO: FC = () => {
  return (
    <div className="bg-primary text-white text-center py-5">
      <div className="max-w-[1000px] m-auto">
        <BlockQuote>
          My reputation was built on helping individuals at all income levels plan for retirement. Now, I want to help a
          new generation to leverage their potential and attain financial security today. At BEP, we are committed to
          building and delivering a new era of finance.
        </BlockQuote>
        <cite>
          <p>Gus Sauter, CEO</p>
          <p>Former CIO, Vanguard</p>
        </cite>
      </div>
    </div>
  );
};
