import { FC } from 'react';

import useScreenSize from '../../hooks/screenSize';
import { DesktopNav } from './desktopVersion';
import { MobileNav } from './mobileVersion';

export const TopNav: FC<{
  scrollToSolutions: () => void;
  scrollToLeadership: () => void;
  scrollToContact: () => void;
}> = ({ scrollToSolutions, scrollToLeadership, scrollToContact }) => {
  const screenSize = useScreenSize();

  return screenSize === 'sm' ? (
    <MobileNav
      scrollToSolutions={scrollToSolutions}
      scrollToLeadership={scrollToLeadership}
      scrollToContact={scrollToContact}
    />
  ) : (
    <DesktopNav
      scrollToSolutions={scrollToSolutions}
      scrollToLeadership={scrollToLeadership}
      scrollToContact={scrollToContact}
    />
  );
};
