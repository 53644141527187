import { FC } from 'react';

import useScreenSize from '../../hooks/screenSize';

export const LeaderCard: FC<{ imgSrc: string; imgAlt: string; name: string; bio: string[] }> = ({
  imgSrc,
  imgAlt,
  name,
  bio,
}) => {
  const screenSize = useScreenSize();

  return (
    <div className="flex sm:flex-col md:flex-col items-center justify-center w-[600px] max-w-[75%] sm: max-w-[95%] py-5 sm:py-10">
      <img className="rounded-full grayscale h-[150px] w-[150px] object-cover" src={imgSrc} alt={imgAlt} />
      <div className="px-5">
        <h4 className="font-bold text-md text-primary sm:text-center">{name}</h4>
        {screenSize === 'sm' ? (
          <p className="text-xs text-center">{bio[0]}</p>
        ) : (
          bio.map((bioLine, idx) => (
            <p key={`${name.split(/\s+/).join('-').toLowerCase()}-bio-line-${idx}`} className="text-xs">
              {bioLine}
            </p>
          ))
        )}
      </div>
    </div>
  );
};
