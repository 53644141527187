import { FC } from 'react';

export const TopImageAndDescription: FC<{ scrollToSolutions: () => void }> = ({ scrollToSolutions }) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-1">
      <div className="order-1 sm:order-2 p-20 sm:p-10 md:p-5 text-3xl">
        <h1 className="font-bold py-8 text-4xl mainPageScreenMediumFontResize:text-2xl">Maximize Earning Potential</h1>
        <p className="py-10 mainPageScreenMediumFontResize:py-8 mainPageScreenMediumFontResize:text-xl">
          Our financial engagement platform gives individuals untapped access to their future by connecting users to
          investors and matching performance with upfront cash for equity, not debt.
        </p>
        {/* Trace todo - hook up button */}
        <button className="bg-primary text-white px-3 py-2 rounded-sm" onClick={scrollToSolutions}>
          Learn More
        </button>
      </div>
      <img
        src={process.env.PUBLIC_URL + 'pexels-stefan-11864633.jpg'}
        className="order-2 sm:order-1 max-h-[1000px] sm:max-h-[500px] w-full"
      />
    </div>
  );
};
