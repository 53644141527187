import { FC } from 'react';

export const PlatformVisual: FC = () => (
  <div className="bg-black">
    <div className="px-2 bg-black flex gap-10 sm:gap-5 max-w-[600px] m-auto">
      <div className="max-w-[1000px] m-auto py-10">
        <div className="bg-primary rounded-full text-white w-[200px] h-[200px] sm:w-[100px] sm:h-[100px] flex flex-col items-center justify-center text-center">
          <p className="text-2xl sm:text-sm font-bold">Investors</p>
          <p className="text-xl sm:text-sm">seeking returns</p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center min-w-[200px] sm:min-w-[100px]">
        <img src={process.env.PUBLIC_URL + 'RightArrow.png'} className="w-[200px] sm:w-[150px]" />
        <img src={process.env.PUBLIC_URL + 'BEPLogo.png'} className="w-[200px] sm:w-[170px]" />
        <img src={process.env.PUBLIC_URL + 'Leftarrow.png'} className="w-[200px] sm:w-[150px]" />
      </div>
      <div className="max-w-[1000px] m-auto py-10">
        <div className="bg-primary rounded-full text-white w-[200px] h-[200px] sm:w-[100px] sm:h-[100px] flex flex-col items-center justify-center text-center">
          <p className="text-2xl sm:text-sm font-bold">Individuals</p>
          <p className="text-xl sm:text-sm">seeking cash flows</p>
        </div>
      </div>
    </div>
  </div>
);
