import { FC, RefObject } from 'react';

export const CallToActionHeader: FC<{ divRef: RefObject<HTMLDivElement> }> = ({ divRef }) => (
  <div ref={divRef} className="bg-black text-center text-white text-2xl sm:text-xl py-5 pt-10">
    <div className="flex justify-around mx-auto max-w-[600px]">
      <div />
      <p className="w-[600px]">Join the movement to empower.</p>
      <img src={process.env.PUBLIC_URL + 'BEPIcon.png'} className="max-w-[100px] min-w-[75px]" />
      <div />
    </div>
  </div>
);
