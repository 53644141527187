import { FC, RefObject } from 'react';

import { SolutionsCard } from '../SolutionsCard';

export const OurSolutions: FC<{ divRef: RefObject<HTMLDivElement> }> = ({ divRef }) => (
  <div
    ref={divRef}
    className="py-5 px-1 grid grid-cols-3 sm:grid-cols-1 justify-around justify-items-center items-center gap-7"
  >
    <SolutionsCard
      imageName="gradcap.png"
      imageAlt="education icon"
      title="Educate"
      text="Users learn about market finance and how to grow future performance"
    />
    <SolutionsCard
      imageName="equity.png"
      imageAlt="equity icon"
      title="Equity"
      text="Users sell an equity stake in their future earnings to investors"
    />
    <SolutionsCard
      imageName="magnet.png"
      imageAlt="empower icon"
      title="Empower"
      text="Investors connect with users to enhance their earning potential"
    />
  </div>
);
