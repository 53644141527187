import { FC, RefObject } from 'react';

import { LeaderCard } from './LeaderCard';

export const LeadershipSection: FC<{ divRef: RefObject<HTMLDivElement> }> = ({ divRef }) => (
  <div className="py-10" ref={divRef}>
    <div>
      <h2 className="text-2xl font-bold text-center">Leadership</h2>
      <p className="text-xl text-center">with reputations built on helping individuals maximize their potential</p>
    </div>
    <div className="grid grid-cols-2 sm:grid-cols-1 w-[70%] m-auto max-w-[1100px] min-w-[275px] items-center justify-center">
      <LeaderCard
        imgSrc={process.env.PUBLIC_URL + 'gus_sauter.png'}
        imgAlt="Gus Sauter"
        name="Gus Sauter"
        bio={[
          'Co-founder, Chief Executive Officer and Director',
          'Former Chief Investment Officer of Vanguard',
          'Member of six investment committees',
        ]}
      />
      <LeaderCard
        imgSrc={process.env.PUBLIC_URL + 'immanuel_thangaraj.png'}
        imgAlt="Immanuel Thangaraj"
        name="Immanuel Thangaraj"
        bio={[
          'Co-founder, Executive Chairman',
          'Managing Director of Park Lane Ventures ',
          'Former Managing Director of Essex Woodlands Former Managing Director of ARCH Venture Partners',
        ]}
      />
      <LeaderCard
        imgSrc={process.env.PUBLIC_URL + 'luigi_zingales.png'}
        imgAlt="Luigi Zingales"
        name="Luigi Zingales"
        bio={[
          'Director of Strategy',
          'Robert C. McCormack Distinguished Service Professor of Entrepreneurship and Finance Fischer Black Visiting Professor of Finance at MIT',
        ]}
      />
      <LeaderCard
        imgSrc={process.env.PUBLIC_URL + 'peter_vitulli.png'}
        imgAlt="Peter Vitulli"
        name="Peter Vitulli"
        bio={[
          'Director and Interim Head of Marketing',
          'Former President of Gatorade',
          'Former CEO of Amerifit Nutrition',
          'Former CEO of DNA Diagnostics Center',
        ]}
      />
      <LeaderCard
        imgSrc={process.env.PUBLIC_URL + 'jim_kapolas.png'}
        imgAlt="James H. Kapolas"
        name="James H. Kapolas"
        bio={[
          'Director of Operations',
          'Founding member of Lift HCM',
          'Founding partner of RPS/Advantage Payroll Founding member of iSystems, LLC',
        ]}
      />
      <LeaderCard
        imgSrc={process.env.PUBLIC_URL + 'trace_sauter.png'}
        imgAlt="Trace Sauter"
        name="Trace Sauter"
        bio={[
          'Director of Technology',
          'Former Chief Technology Officer of Swimmingly Former Risk Analyst at Bank of America',
        ]}
      />
      <LeaderCard
        imgSrc={process.env.PUBLIC_URL + 'kavitha_davidson.png'}
        imgAlt="Kavitha Davidson"
        name="Kavitha Davidson"
        bio={[
          'Director of Communications',
          'Emmy Award-winning journalist',
          'Previously of HBO, The Athletic, ESPN, and Bloomberg',
        ]}
      />
    </div>
  </div>
);
