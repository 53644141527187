import { FC } from 'react';

export const ProblemAndSolution: FC = () => (
  <div className="bg-black text-gray-200 text-xl sm:text-2xl mainPageScreenMediumFontResize:text-lg py-2 px-5 mainPageScreenMediumFontResize:px-3">
    <p>Most people lack an understanding of how to maximize their financial potential</p>
    <p className="text-2xl sm:text-xl font-bold text-white">
      <span className="text-primary">BEP</span> empowers users to secure their financial future
    </p>
  </div>
);
