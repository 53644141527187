import { FC } from 'react';

import { solutionsCardMaxWidth } from '../../utils/componentSpecificHardCodes';

export const SolutionsCard: FC<{ imageName: string; imageAlt: string; title: string; text: string }> = ({
  imageName,
  imageAlt,
  title,
  text,
}) => {
  return (
    <div className={`flex flex-col items-center justify-center text-center max-w-[${solutionsCardMaxWidth}]`}>
      <img src={process.env.PUBLIC_URL + imageName} alt={imageAlt} className="h-16" />
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  );
};
